.text-area {
  height: 90px;
  resize: none;
}

.radio-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 0;
}

.checkbox-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  
  span {
    margin-left: 5px;
  }
}

