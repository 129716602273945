@import "styles/global.scss";

.error {
  margin: 40px 0;
  flex-direction: column;
}

.error h1,
.error h3 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .error h1 {
    font-size: 50px;
  }

  .error h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .error h1 {
    font-size: 40px;
  }

  .error h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 576px) {
  .error h1 {
    font-size: 30px;
  }

  .error h3 {
    font-size: 16px;
  }
}
