@import "styles/global.scss";

.input-search {
  padding: 0.2rem 50px 0.2rem 0.5rem !important;
}

.btn-search {
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 45px;  
  height: 28px;
  padding: 0;
  border-radius: 0.25rem;
  float: right;
}

.search-items {
  font-size: 14px;
  width: 85%;
  height: 50px;
  border: 1px solid $color-gray-concrete;
  padding-top: 15px;
}

.search-result {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
