@import "styles/global.scss";

.cart-items {
  width: 100%;
  margin-top: 50px;
}

.cart-header {
  display: table-header-group;
  text-align: left;
}

.cart-item img {
  width: 135px;
}

.cart-item-info li {
  width: 100%;
  margin-top: 8px;
}

.cart-item-title {
  color: $color-black-text;
}

.cart-item-description {
  color: $color-gray;
}

.cart-item-price {
  font-weight: 500;
  color: $color-base;
  font-size: 20px;
}

.cart-item-price span {
  font-size: 16px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.cart-item-description-option {
  margin-top: 15px;
}

.cart-item-remove {
  position: absolute;
  right: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 0.125rem;
  min-width: auto;
  padding: 0;
  z-index: 1;
}

.checkout {
  float: right;
  width: 50%;
}

.checkout-details li {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.checkout-button {
  width: 100%;
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  .checkout {
    width: 100%;
  }
}


.color-cart {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: inline-block;
  background-size: contain;
  margin-left: 15px;
}

.span-strong {
  position: absolute;
  font-size: 9px;
  transform: translate(0,-17px);
  margin-left: 16px;
}

.color-span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-block;
  background-size: contain;
  margin-left: 16px;
  margin-top: 7px;
}

.descriptions-col {
  margin-top: 14px !important;
}
.description-span {
  text-align: center;
  //line-height: 100px;
}