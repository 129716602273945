@import "styles/global.scss";

.footer {
  background-color: $color-black-text;
  color: $color-white;
  font-size: 14px;
}

.footer-top {
  padding: 60px 0;
}

.footer-column .logo-container a span:first-child,
.footer-column .logo-container a span:last-child {
  color: $color-white;
}

.footer-column .logo-container a:hover span {
  color: $color-gray-light;
}

.footer-column .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: $color-gray-light;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: $color-base;
  color: $color-white;
  font-size: 18px;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  transition: 0.3s;
}

.copyright-wrap {
  border-top: 1px solid $color-base;
}

.copyright a {
  color: $color-base;
}

.footer-column ul li a,
.copyright a:hover {
  color: $color-gray-light;
}

.social-links a:hover {
  background-color: $color-gray-light;
  color: $color-white;
  text-decoration: none;
}

.footer-column ul li a:hover {
  color: $color-base;
}

@media only screen and (max-width: 991px) {
  .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }

  .footer-column {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .social-links {
    justify-content: flex-start;
  }
}
