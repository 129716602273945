@import "styles/global.scss";

.services-container {
  margin: 20px 0 60px;
}

.service {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  gap: 15px;
}

.service span {
  font-size: 12px;
  line-height: 1.8em;
  letter-spacing: 1px;
}

.service img {
  width: 64px;
}

.furniture-img {
  width: 100%;
  flex-grow: 1;
  margin-bottom: 32px;
  padding: 0 30px 20px 0;
}

.sells {
  display: flex;
  padding: 60px 20px;
  background-color: $color-base;
  margin-bottom: 40px;
}

.sells h3 {
  color: $color-white;
}

@media only screen and (max-width: 767px) {
  .furniture-img {
    padding: 0;
  }
}
