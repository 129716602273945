@import "styles/global.scss";

.modal {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
}

.close {
  min-width: 0;
  line-height: 0.5;
}

.modal-content {
  padding: 15px;
  border: none;
  z-index: 1;
}
