@import "styles/global.scss";

.products-loading {
  width: 100%;
  margin-top: 60px;
}

.main-content {
  float: left;
  width: calc(100% - 200px);
  padding-left: 34px;
}

.sidebar {
  float: left;
  width: 200px;
  padding-right: 17px;
}

.sidebar-section {
  padding-bottom: 35px;
  margin-bottom: 50px;
  border-bottom: solid 1px $color-gray-concrete;
}

.sidebar-title h5 {
  font-size: 18px;
  margin-bottom: 21px;
}

.sidebar-categories li a {
  font-size: 14px;
  line-height: 40px;
  color: $color-black-text;
}

.sidebar-categories li a.active {
  color: $color-base;
}

.filter-price-button {
  width: 100%;
  height: 30px;
  background: $color-black-text;
  text-align: center;
  margin-top: 25px;
}

.offers {
  margin-left: 5px;
  text-align: center;
  color: $color-black-text;
  height: 30px;
}

.ordenar {
  text-align: center;
  color: $color-black-text;
}

.selectOrdenar {
  width: 80px;
  margin-left: 5px;
}
.filterOrdenar {
  margin-top: 10px;
}
.paginated {
  justify-content: start;
}

.MuiPagination-ul {
  justify-content: center;
}

.MuiPaginationItem-root {
  color: $color-base !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: $color-base !important;
  color: $color-white !important;
  border-color: $color-base;
}

.filter-price-button:hover {
  background: $color-black-light;
}

.sidebar-categories li a:hover {
  color: $color-gray-light;
}

.sidebar-categories li a.active:hover {
  color: $color-base-hover;
}

.show-filter-categorys {
  display: 'block' !important;
}

.show-filter-categorys-secondary {
  display: none !important;
}

@media only screen and (max-width: 1199px) {
  .sidebar {
    float: none;
    width: 100%;
    padding-right: 0;
  }

  .main-content {
    float: none;
    width: 100%;
    padding-left: 0;
  }

  .paginated {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .show-filter-categorys {
    display: none !important;
  }
  .show-filter-categorys-secondary {
    display: block !important;
  }
}