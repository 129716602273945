@import "styles/global.scss";

.color-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-block;
  background-size: contain;
}

.color-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 10px;
}

.description-name {
  margin-right: 15px;
}
