@import "styles/global.scss";

.whatsapp {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99;
  background-color: $color-green-whatsapp;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  opacity: 1;
  border-radius: 50%;
}

.whatsapp-image {
  width: 35px;
  height: 35px;
  margin: 15px 15px;
}

.whatsapp:hover {
  opacity: 0.8;
}
