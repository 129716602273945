@import "styles/global.scss";

.breadcrumbs {
  width: 100%;
  height: 60px;
  border-bottom: solid 1px $color-gray-concrete;
  margin-bottom: 58px;
}

.breadcrumbs ul li {
  display: inline-block;
  margin-right: 17px;
}

.breadcrumbs ul li i {
  margin-right: 17px;
}

.breadcrumbs ul li a {
  color: $color-gray-dark;
}

.breadcrumbs ul li:last-child a {
  color: $color-gray-light;
}
