@import "styles/global.scss";

.profile-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.user-form {
  margin-bottom: 40px;
}
