@import "styles/global.scss";

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: $color-white;
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.5s;
}
