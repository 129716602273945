@import "styles/global.scss";

.minimum-purchase {
  margin-top: 30px;
  color: $color-red;
}

.secure-checkout-button {
  background-color: #009ee3;
}

.secure-checkout-button:hover {
  background-color: #005e88;
}

.secure-checkout-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 767px) {
  .checkout-cart-summary {
    margin-top: 30px;
  }
}
