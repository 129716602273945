@import "styles/global.scss";

.products-menu-mobile .products-menu-mobile-toggle {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 1.5em;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  margin-bottom: 0;
  cursor: pointer;
}

.products-menu-mobile input[type="checkbox"],
.products-menu-mobile .products-sub-menu-mobile {
  display: none;
}

.products-menu-mobile li {
  padding: 0 10px;
  line-height: 50px;
}

.products-menu-mobile-toggle:hover {
  color: $color-gray-light;
}

.products-menu-mobile input[type="checkbox"]:checked + .products-sub-menu-mobile {
  display: block;
}
