@import "styles/global.scss";

#cardPaymentBrick_container {
    input[type=text], input[type=number], input[type=email], select {
        height: 100% !important;
    }
}

.secure-checkout-form {
    position: relative;
    min-height: 400px;
}

.secure-checkout-form-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
