@import "styles/global.scss";

.logo-container {
  margin-right: 16px;
}

.logo-image {
  width: 100px;
}

@media only screen and (max-width: 991px) {
  .logo-container {
    margin-right: 0;
  }
}
