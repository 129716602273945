@import "styles/global.scss";

.summary-items {
  width: 100%;
  font-size: 14px;
  height: 318px;
  margin-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.summary-item-info {
  padding: 0 30px;
}

.summary-item-image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
  }
}

.summary-item-title {
  color: $color-black-text;
}

.summary-item-description {
  color: $color-gray;
}

.summary-item-price {
  font-weight: 500;
  color: $color-base;
}

.summary-item-price span {
  font-size: 12px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.summary-subtotal {
  width: 100%;
  margin-top: 20px;
}
