@import "styles/global.scss";

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 0 25px 29px rgba(63, 78, 100, 0.15);
  border: solid 2px rgba(235, 235, 235, 1);
}

.product {
  width: 100%;
  border-right: solid 1px $color-gray-concrete;
  cursor: pointer;
}

.product-image {
  width: 100% !important;
  padding: 30px !important;
}

.product-image img {
  height: 150px;
}

.product-info {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.product-name a {
  color: $color-black-text;
  line-height: 20px;
}

.product-price {
  font-size: 16px;
  color: $color-base;
  font-weight: 600;
}

.product-price span {
  font-size: 12px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-info .product-price {
  font-size: 14px;
}

.product-quantity-selector {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.product-out-of-stock {
  text-align: center;
  color: $color-red;
}

.product-name:hover a {
  color: $color-gray-light;
}

@media only screen and (max-width: 576px) {
  .product-image {
    width: 100%;
    padding: 10px;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .product-quantity-selector {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
  }
}
