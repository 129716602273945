@import "styles/global.scss";

.main-slider {
  width: 100%;
  height: 100%;
}

.main-slider-img {
  width: 100%;
  height: 100%;
}

.main-slider-video {
  width: 100%;
  height: 400px;
}

.new-arrivals-sorting {
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 50px;
  overflow: visible;
  width: 100%;
}

.arrivals-grid-sorting {
  display: flex;
  justify-content: center;
  width: 100%;
}

.grid-sorting-button {
  height: 40px;
  min-width: 102px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  border: solid 1px $color-gray-iron;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background: $color-white;
  border-radius: 3px;
  margin: 0;
  float: left;
  margin-left: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-sorting-button:first-child {
  margin-left: 0;
}

.grid-sorting-button.active {
  color: $color-white;
  background: $color-base;
}

.grid-sorting-button.active:hover {
  background: $color-base-hover !important;
}

.product-slider-container {
  margin-bottom: 74px;
  justify-content: center;
}

@media only screen and (max-width: 576px) {
  .arrivals-grid-sorting {
    display: flex;
    flex-direction: column;
  }

  .arrivals-grid-sorting li {
    margin-bottom: 10px;
  }
}
